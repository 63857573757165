export enum ONBOARDING {
  SIGN_UP = "sign-up",
  SUCCESSFUL = "successful",
  SELECT_COUNTRY = "select-country",
  SELECT_REWARD = "select-reward",
  SUGGESTIONS = "suggestions",
  REFERRAL_CODE = "referral-code",
  USERNAME = "username",
  CONGRATULATIONS = "congratulations",
  START_DOWNLOAD = "start-download",
  DOWNLOAD_IN_PROGRESS = "download-in-progress",
  LOGGING_IN = "logging-in",
  LOG_IN = "log-in",
  WELCOME_BACK = "welcome-back",
}

export enum TOPUP {
  PAYMENT_INITIATION = "/payments/initiate",
  PAYMENT_CONFIRMATION = "/payments/:provider/confirm",
  FIRST_TOPUP_SUCCESSFUL = "/successful-top-up/first",
  RETURN_TOPUP_SUCCESSFUL = "/successful-top-up/return",
  FAILED = "/payments/failed-top-up",
  PENDING = "/payments/pending-top-up",
}

export enum ROOT {
  ONBOARDING = "/onboarding",
  DOWNLOAD_NOW = "/download-now",
}
