import React, { useRef } from "react";
import { Col, Image } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";

import HyperIconPurple from "../../../images/hyper-text-icon-purple.svg";
import FileMightBeHarmful from "../../../images/file-might-be-harmul-image.png";
import SSLImage from "../../../images/ssl-image.png";
import FacePile from "../../../images/face-pile.png";
import PrimaryActionButton from "../../../components/Buttons/PrimaryActionButton/PrimaryActionButton";
import "./DownloadInstructionsPage1.scss";
import { useDownloadApp } from "../../../hooks/useDownloadApp";
import { ROUTES, PAGE_NAMES, TRACKING_EVENTS } from "../../../utils/constants";
import { getCurrentUrl, registerTrackingEvent } from "../../../utils/helpers";
import { showSuccessToast } from "../../../utils/show-toast";

const nextPagePath = `${ROUTES.ROOT.ONBOARDING}/${ROUTES.ONBOARDING.SELECT_REWARD}`;

function DownloadInstructionsPage1() {
  const { apk } = useDownloadApp();
  const timeoutIdRef = useRef<NodeJS.Timer | null>(null);
  const navigate = useNavigate();

  function handleStartDownloadClick() {
    timeoutIdRef.current && clearTimeout(timeoutIdRef.current);
    registerTrackingEvent({
      name: TRACKING_EVENTS.DOWNLOAD_STARTED,
      payload: { page_name: PAGE_NAMES.startDownload, url: getCurrentUrl() },
    });
    showSuccessToast("Download Started");
    navigate(nextPagePath);
  }

  return (
    <div className="MobileOnly position-relative download-instructions-page-1 px-0">
      <Col className="download-instructions-page-1-content  flex-column justify-content-center">
        <Link to="/">
          <Image src={HyperIconPurple} alt="hyper_icon_ourple" />
        </Link>
        <div className="px-3">
          <h1 className="to-start-download-text text-black">
            To Start Download
          </h1>
          <p className="tap-download-instructions text-black">
            Tap <b>"Download anyway"</b> when you see this pop up 👇
          </p>
          <Image
            src={FileMightBeHarmful}
            alt="file-might-be-harmful-image"
            className="file-harmful-image"
          />
          <Image src={SSLImage} alt="ssl_image" className="ssl-image mt-3" />

          <div className="trusted-by-container  MobileOnly">
            <p className="trusted-by-statement text-black">
              {" "}
              <b>Trusted by over 10 thousand users </b>
            </p>
            <Image className="face-pile" src={FacePile} alt="face_pile" />
          </div>
        </div>

        <div className="download-instructions-footer px-3 MobileOnly">
          <PrimaryActionButton
            id="start_dl_btn"
            textColor={"#FFFFFF"}
            text="start download"
            textClassName="start-download-btn-text"
            onClick={handleStartDownloadClick}
            href={apk.url}
            style={{
              display: "flex",
              justifyContent: "center",
              textTransform: "uppercase",
              height: 50,
            }}
          />
        </div>
      </Col>
    </div>
  );
}

export default DownloadInstructionsPage1;
