import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { OAuthProvider } from "@nestcoinco/hyper-api-gateway-api-client";

import GoogleSmallButtonIcon from "../../../images/google_small_button_icon.svg";
import GamePadLoader from "../../../images/Gaming_loader.svg";
import DefaultOnboardingStatusPage from "../../ReusablePages/DefaultOnboardingStatusPage/DefaultOnboardingStatusPage";
import { handleVerifyAuth } from "../../../api-clients/usersAuthClient";
import {
  getStoredAuthProvider,
  setStoredAuthCancelledError,
  setStoredUser,
} from "../../../utils/storage";
import FacebookLogo from "../../../images/facebook-logo.svg";
import { ROUTES } from "../../../utils/constants";

const basePath = ROUTES.ROOT.ONBOARDING;
const fallbackPath = `${basePath}/${ROUTES.ONBOARDING.SIGN_UP}`;

function LoggingInPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const [token, setToken] = useState<string>();
  const base64Data = queryParams.get("data");
  const base64Error = queryParams.get("error");
  const titleText = `Signing up with ${
    getStoredAuthProvider() === OAuthProvider.FACEBOOK
      ? "Facebook"
      : getStoredAuthProvider() === OAuthProvider.GOOGLE
      ? "Google"
      : ""
  }...`;

  useEffect(() => {
    if (!base64Error) return;
    setStoredAuthCancelledError(JSON.parse(atob(base64Error)));
    navigate(fallbackPath);
  }, [base64Error, navigate]);

  useEffect(() => {
    function getToken(data: string) {
      const { idToken } = JSON.parse(atob(data));
      idToken && setToken(idToken);
    }
    base64Data && getToken(base64Data);
  }, [base64Data]);

  useEffect(() => {
    const authProvider = getStoredAuthProvider();
    if (!authProvider) {
      navigate(fallbackPath);
      return;
    }
    if (!token) return;
    const verifyAuth = async () => {
      const user = await handleVerifyAuth(token, authProvider);
      if (!user) {
        navigate(fallbackPath);
        return;
      }
      setStoredUser(user);
      const redirectPath = user.isReturning
        ? `${basePath}/${ROUTES.ONBOARDING.LOG_IN}`
        : `${basePath}/${ROUTES.ONBOARDING.SUCCESSFUL}`;
      navigate(redirectPath);
    };
    verifyAuth();
  }, [navigate, token]);

  return (
    <div>
      <DefaultOnboardingStatusPage
        titleText={titleText}
        secondaryText="Please wait a moment while we create your account"
        primaryImage={
          getStoredAuthProvider() === OAuthProvider.FACEBOOK
            ? FacebookLogo
            : GoogleSmallButtonIcon
        }
        secondaryImage={GamePadLoader}
        stayOnPage
      />
    </div>
  );
}

export default LoggingInPage;
