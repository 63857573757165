import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import Image from "react-bootstrap/Image";
import { Helmet } from "react-helmet-async";
import Container from "react-bootstrap/Container";
import games from "../../contents/games";
import whyPlay from "../../contents/why-play";
import WhatsappImg from "../../images/whatsapp.svg";
import Logo from "../../images/hyper-full-white.svg";
import ChooseGame from "../../images/choose-game.png";
import ChallengeGameResults from "../../images/challenge-game-results.png";
import gameConfig, { IGameConfig } from "../../contents/game-config";
import MetaImage from "../../images/game-detail-meta-image.png";
import "./GameDetail.scss";
import {
  LEGACY_FLOW_SUFFIX,
  PAGE_NAMES,
  TRACKING_EVENTS,
  ROUTES,
  LINKS,
} from "../../utils/constants";
import {
  checkIsAndroidDevice,
  getCurrentUrl,
  registerTrackingEvent,
} from "../../utils/helpers";
import BirdBump from "../../images/games/bird-bump-thumbnail.png";
import XIconImg from "../../images/x.svg";
import { useDownloadApp } from "../../hooks/useDownloadApp";

type IGameDetailProps = {
  slug: string;
};

function GameDetail({ slug }: IGameDetailProps) {
  useDownloadApp(); // prefetch apk for use later on
  const [config] = useState<IGameConfig>(gameConfig[slug]);
  const isAndroid = checkIsAndroidDevice();
  const { pathname } = useLocation();
  const isLegacyFlow = pathname[pathname.length - 1] === LEGACY_FLOW_SUFFIX;
  const nextPagePath = isLegacyFlow
    ? ROUTES.ROOT.DOWNLOAD_NOW
    : `${ROUTES.ROOT.ONBOARDING}/${ROUTES.ONBOARDING.START_DOWNLOAD}`;

  const handleDownloadClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    if (!isAndroid) {
      e.preventDefault();
      return;
    }
    registerTrackingEvent({
      name: TRACKING_EVENTS.WEB_DOWNLOAD,
      payload: { page_name: PAGE_NAMES.home, url: getCurrentUrl() },
    });
  };

  return (
    <>
      <Helmet>
        <title>
          Play {config.name}, Compete & Win 💰💰💰 | Hyper by Metaverse Magna
        </title>
        <meta
          name="description"
          content="Step 1 to make 💰 from gaming: Sign up on Hyper & claim your welcome bonus"
        />
        <meta
          name="og:title"
          content={`Play ${config.name}, Compete & Win 💰💰💰 | Hyper by Metaverse Magna`}
        />
        <meta
          name="og:description"
          content="Step 1 to make 💰 from gaming: Sign up on Hyper & claim your welcome bonus"
        />
        <meta name="og:image" content={config.metaImage || MetaImage} />
      </Helmet>
      <Container fluid className="GameDetail MobileOnly position-relative">
        <Col className="App-bar d-flex justify-content-center px-0 py-4 position-fixed MobileOnly">
          <Link to="/">
            <Image className="img" src={Logo}></Image>
          </Link>
        </Col>
        <Row
          as="header"
          className="Header text-white text-center text-md-start d-flex flex-column position-relative pt-4"
        >
          <Col className="pb-3 isolate pt-4 d-flex flex-column align-items-center">
            <Col
              as="h1"
              className="font-large text-uppercase text-center flex-grow-0 px-2 px-sm-2 pb-1 pt-5"
            >
              Play {config.name}, Compete & Win 💰💰💰
            </Col>
            <Image className="banner" src={config.headerBanner} />
          </Col>
        </Row>
        <Row className="body-content d-flex flex-column">
          <Col className="py-5">
            <h2 className="gradient-text">Why play games on Hyper?</h2>
            <Col className="d-flex justify-content-around pt-3">
              {whyPlay.map((item, i) => (
                <div
                  key={item.key}
                  className="d-flex flex-column align-items-center why-play"
                >
                  <img src={item.image} alt="" />
                  <p className="text-center py-1 mb-0 colored">{item.copy}</p>
                  <p className="text-center py-1 text-white mb-0">
                    {item.subCopy}
                  </p>
                </div>
              ))}
            </Col>
          </Col>
          <Col className="py-2 d-flex flex-column  px-4">
            <h2 className="gradient-text pb-2">
              Other exciting Games we have on Hyper
            </h2>
            <Col className="game-grid py-4">
              {games?.length &&
                games
                  .filter((item) => item.key !== slug)
                  .map((item, i) => (
                    <GameItem
                      key={i}
                      image={item.image}
                      content={item.content}
                    />
                  ))}
              {games.filter((item) => item.key !== slug).length < 6 && (
                <GameItem image={BirdBump} content="Bird Bump" />
              )}
            </Col>
          </Col>
          <Col className="py-0 d-flex flex-column px-3">
            <h2 className="py-3">
              <span className="gradient-text">How to play games </span>{" "}
              <span className="gradient-text"> and win</span> 💰💰💰
            </h2>
            <Col className="how-to-container green text-white d-flex flex-column align-items-center pt-4 px-4 my-2">
              <h3 className="count text-white mb-4">1</h3>
              <h6>Download Hyper & Sign up</h6>
              <p>
                Tap download & install the app from your phone's download folder
                to sign up.
              </p>
              <img src={config.signupBanner} alt="" />
            </Col>
            <Col className="how-to-container blue text-white d-flex flex-column align-items-center pt-4 px-4 my-4">
              <h3 className="count text-white mb-4">2</h3>
              <h6>Choose a Game</h6>
              <p>
                Play for free or Play for cash in multi-player tournaments &
                head-to-head contests.
              </p>
              <img src={ChooseGame} alt="" />
            </Col>
            <Col className="how-to-container orange text-white d-flex flex-column align-items-center pt-4 px-4 my-4">
              <h3 className="count text-white mb-4">3</h3>
              <h6>Beat your Opponent & Win Cash</h6>
              <p>
                Set a high score in every game you play to beat your opponent &
                win the 💰 prize.
              </p>
              <img src={ChallengeGameResults} alt="Challenge game results" />
            </Col>
          </Col>
        </Row>
        <Row className="foot d-flex flex-column">
          <Col className="logo-container">
            <Image src={Logo} />
          </Col>
          <Col className="d-flex justify-content-center pb-3">
            <Row className="d-flex justify-content-between w-100">
              <Col className="d-flex justify-content-start align-items-end w-100 p-0 gap-3">
                <a
                  href={LINKS.SOCIAL.X}
                  target="_blank"
                  rel="noreferrer"
                  aria-label="x"
                >
                  <Image src={XIconImg} alt="x icon" />
                </a>
                <a
                  href={LINKS.SOCIAL.WHATSAPP}
                  target="_blank"
                  rel="noreferrer"
                  aria-label="whatsapp"
                >
                  <Image src={WhatsappImg} alt="whatsapp icon" />
                </a>
              </Col>
              <Col className="d-flex justify-content-end align-items-end w-100 p-0 gap-2">
                <a
                  href={LINKS.WEBPAGE.PRIVACY_POLICY}
                  target="_blank"
                  rel="noreferrer"
                  className="link-text"
                >
                  Privacy Policy
                </a>
                <a
                  href={LINKS.WEBPAGE.TERMS_AND_CONDITIONS}
                  target="_blank"
                  rel="noreferrer"
                  className="link-text"
                >
                  Terms of service
                </a>
              </Col>
            </Row>
          </Col>
        </Row>

        <Col className="App-footer d-flex justify-content-center px-0 py-3 position-fixed MobileOnly">
          <Link
            onClick={handleDownloadClick}
            to={nextPagePath}
            data-disabled={!isAndroid}
            aria-disabled={!isAndroid}
            className="download font-reg"
          >
            {isAndroid ? (
              <>
                <span className="download-and-get d-inline-block mx-1">
                  Download &amp; get{" "}
                </span>
                <span className="hundred-percent-bonus-fire">
                  100% bonus 🔥
                </span>
              </>
            ) : (
              <span className="only-android-text">
                Available for Android Only
              </span>
            )}
          </Link>
        </Col>
      </Container>
    </>
  );
}

export default GameDetail;

function GameItem({ image, content }: { image: string; content: string }) {
  return (
    <div className="d-flex flex-column fancy-border game-item p-1 justify-content-between">
      <Image src={image} alt="game_thumbnail" />
      <p className="text-center py-1 text-white mb-0">{content}</p>
    </div>
  );
}
