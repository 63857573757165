import { UAParser } from "ua-parser-js";

import { TRACKING_EVENTS } from "./constants";

export function checkIsAndroidDevice() {
  const parser = new UAParser();
  const uaResult = parser.getResult();
  return !!uaResult.os.name?.includes("Android");
}

export function formatCurrency(value: number, currency?: "dollar" | "naira") {
  const symbol = currency === "dollar" ? "$" : currency === "naira" ? "₦" : "";
  if (isNaN(value)) {
    return "Invalid number";
  }
  // Convert number to a whole number and then to a string with commas
  const formattedNumber = Math.round(value)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return `${symbol}${formattedNumber}`;
}

type TrackingEventNameType =
  (typeof TRACKING_EVENTS)[keyof typeof TRACKING_EVENTS];
export function registerTrackingEvent({
  name,
  payload,
}: {
  name: TrackingEventNameType;
  payload?: any;
}) {
  (window as any)?.analytics?.track(name, payload);
}

export const getCurrentUrl = () => window.location.href;

type IdentityPayloadType = {
  name: string;
  username: string;
  email: string;
};

export function registerIdentityEvent(
  userId: string,
  payload: IdentityPayloadType
) {
  (window as any)?.analytics?.identify(userId, {
    ...payload,
    sign_up_type: "web",
  });
}

export function getTimeAgo(dateString?: string) {
  if (!dateString) return "";

  const inputDate = new Date(dateString);
  const now = new Date();

  const secondsAgo = Math.floor((now.getTime() - inputDate.getTime()) / 1000);
  const minutesAgo = Math.floor(secondsAgo / 60);
  const hoursAgo = Math.floor(minutesAgo / 60);

  if (secondsAgo < 60) {
    return `${secondsAgo} seconds ago`;
  } else if (minutesAgo < 60) {
    return `${minutesAgo} minutes ago`;
  } else {
    return `${hoursAgo} hours ago`;
  }
}
