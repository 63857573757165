import KongClimbImg from "../images/games/kong-climb.png";
import BirdsVsBlocksImg from "../images/games/birds-vs-block.png";
import DartMasterImg from "../images/games/dart-master.png";
import DroidOImg from "../images/games/droid-o.png";
import ElectronDashImg from "../images/games/electron-dash.png";
import FruitSliceFrenzyImg from "../images/games/fruit-slice-frenzy.png";
import GrabTheSushiImg from "../images/games/grab-the-sushi.png";
import HoopStarImg from "../images/games/hoop-star.png";
import SamuraiRampageImg from "../images/games/samurai-rampage.png";
import ZombiesAreComingImg from "../images/games/zombies-are-coming.png";
import WindingRoadImg from "../images/games/winding-road.png";

const games: Array<Record<string, string>> = [
  {
    key: "kong-climb",
    image: KongClimbImg,
    content: "Kong Climb",
  },
  {
    key: "birds-vs-blocks",
    image: BirdsVsBlocksImg,
    content: "Birds vs Blocks",
  },
  {
    key: "dart-master",
    image: DartMasterImg,
    content: "Dart Master",
  },
  {
    key: "droid-o",
    image: DroidOImg,
    content: "Droid O",
  },
  {
    key: "electron-dash",
    image: ElectronDashImg,
    content: "Electron Dash",
  },
  {
    key: "fruit-slice-frenzy",
    image: FruitSliceFrenzyImg,
    content: "Fruit Slice Frenzy",
  },
  {
    key: "grab-the-sushi",
    image: GrabTheSushiImg,
    content: "Grab The Sushi",
  },
  {
    key: "hoop-star",
    image: HoopStarImg,
    content: "Hoop Star",
  },
  {
    key: "samurai-rampage",
    image: SamuraiRampageImg,
    content: "Samurai Rampage",
  },
  {
    key: "zombies-are-comming",
    image: ZombiesAreComingImg,
    content: "Zombies Are Coming",
  },
  {
    key: "winding-road",
    image: WindingRoadImg,
    content: "Winding Road",
  },
];

export default games;
