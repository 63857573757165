import React, { useEffect } from "react";
import { OAuthProvider } from "@nestcoinco/hyper-api-gateway-api-client";

import CheckMarkSuccessful from "../../../images/checkmark_successful.svg";
import DefaultOnboardingStatusPage from "../../ReusablePages/DefaultOnboardingStatusPage/DefaultOnboardingStatusPage";
import {
  registerIdentityEvent,
  registerTrackingEvent,
  getCurrentUrl,
} from "../../../utils/helpers";
import { getStoredUser, getStoredAuthProvider } from "../../../utils/storage";
import { TRACKING_EVENTS } from "../../../utils/constants";

function LoginSuccessfulPage() {
  const user = getStoredUser()?.user;
  const authProvider = getStoredAuthProvider();

  useEffect(() => {
    if (!user || !authProvider) return;

    registerTrackingEvent({
      name: TRACKING_EVENTS.SUCCESSFUL_WEB_LOGIN,
      payload: {
        user_id: user.id,
        email: user.email,
        url: getCurrentUrl(),
      },
    });

    registerTrackingEvent({
      name:
        authProvider === OAuthProvider.GOOGLE
          ? TRACKING_EVENTS.WEB_LOG_IN_WITH_GOOGLE
          : TRACKING_EVENTS.WEB_LOG_IN_WITH_FACEBOOK,
      payload: {
        user_id: user.id,
        email: user.email,
        url: getCurrentUrl(),
      },
    });

    registerIdentityEvent(user.id, {
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
      username: user.username,
    });
  }, [authProvider, user]);

  return (
    <DefaultOnboardingStatusPage
      titleText="Log in Successful"
      secondaryText="You have successfully logged in to Hyper!"
      primaryImage={CheckMarkSuccessful}
      metaData={{
        title: "Log In Successful | Hyper by Metaverse Magna",
        description:
          "You have successfully logged in on Hyper. Start playing now to earn cash",
      }}
      redirectDelay={3000}
    />
  );
}

export default LoginSuccessfulPage;
