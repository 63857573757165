import AtlanticSkyForceHeader from "../images/games/atlantic-sky-force-header.png";
import AtlanticSkyForceSignup from "../images/games/atlantic-sky-force-signup.png";
import BallsterHeader from "../images/games/ballster-header.png";
import BallsterSignup from "../images/games/ballster-signup.png";
import BirdVsBlockHeader from "../images/games/bird-vs-blocks-header.png";
import BirdVsBlockSignup from "../images/games/bird-vs-blocks-signup.png";
import BirdBumpHeader from "../images/games/bird-bump-header.png";
import BirdBumpSignup from "../images/games/bird-bump-signup.png";
import BreakEmBricksHeader from "../images/games/break-em-bricks-header.png";
import BreakEmBricksSignup from "../images/games/break-em-bricks-signup.png";
import CandyBlastHeader from "../images/games/candy-blast-header.png";
import CandyBlastSignup from "../images/games/candy-blast-signup.png";
import EscapeRunnerHeader from "../images/games/escape-runner-header.png";
import EscapeRunnerSignup from "../images/games/escape-runner-signup.png";
import HoopStarHeader from "../images/games/hoop-star-header.png";
import HoopStarSignup from "../images/games/hoop-star-signup.png";
import HunterKillerHeader from "../images/games/hunter-killer-header.png";
import HunterKillerSignup from "../images/games/hunter-killer-signup.png";
import KongClimbHeader from "../images/games/kong-climb-header.png";
import KongClimbSignup from "../images/games/kong-climb-signup.png";
import SamuraiRampageHeader from "../images/games/samurai-rampage-header.png";
import SamuraiRampageSignup from "../images/games/samurai-rampage-signup.png";
import SnakeVsNumbersHeader from "../images/games/snake-vs-numbers-header.png";
import SnakeVsNumbersSignup from "../images/games/snake-vs-numbers-signup.png";
import SporeHunterHeader from "../images/games/spore-hunter-header.png";
import SporeHunterSignup from "../images/games/spore-hunter-signup.png";
import ThreeChickensHeader from "../images/games/three-chickens-header.png";
import ThreeChickensSignup from "../images/games/three-chickens-signup.png";

export type IGameConfig = {
  name: string;
  headerBanner: string;
  signupBanner: string;
  metaImage?: string;
};

const gameConfig: Record<string, IGameConfig> = {
  "atlantic-sky-force": {
    name: "Atlantic Sky Force",
    headerBanner: AtlanticSkyForceHeader,
    signupBanner: AtlanticSkyForceSignup,
  },
  ballster: {
    name: "Ballster",
    headerBanner: BallsterHeader,
    signupBanner: BallsterSignup,
  },
  "bird-vs-blocks": {
    name: "Bird Vs Blocks",
    headerBanner: BirdVsBlockHeader,
    signupBanner: BirdVsBlockSignup,
  },
  "bird-bump": {
    name: "Bird Bump",
    headerBanner: BirdBumpHeader,
    signupBanner: BirdBumpSignup,
  },
  "break-em-bricks": {
    name: "Break 'Em Bricks",
    headerBanner: BreakEmBricksHeader,
    signupBanner: BreakEmBricksSignup,
  },
  "candy-blast": {
    name: "Candy Blast",
    headerBanner: CandyBlastHeader,
    signupBanner: CandyBlastSignup,
  },
  "escape-runner": {
    name: "Escape Runner",
    headerBanner: EscapeRunnerHeader,
    signupBanner: EscapeRunnerSignup,
  },
  "hoop-star": {
    name: "Hoop Star",
    headerBanner: HoopStarHeader,
    signupBanner: HoopStarSignup,
  },
  "hunter-killer": {
    name: "Hunter Killer",
    headerBanner: HunterKillerHeader,
    signupBanner: HunterKillerSignup,
  },
  "kong-climb": {
    name: "Kong",
    headerBanner: KongClimbHeader,
    signupBanner: KongClimbSignup,
  },
  "samurai-rampage": {
    name: "Samurai Rampage",
    headerBanner: SamuraiRampageHeader,
    signupBanner: SamuraiRampageSignup,
  },
  "snake-vs-numbers": {
    name: "Snake vs Numbers ",
    headerBanner: SnakeVsNumbersHeader,
    signupBanner: SnakeVsNumbersSignup,
  },
  "spore-hunter": {
    name: "Spore Hunter",
    headerBanner: SporeHunterHeader,
    signupBanner: SporeHunterSignup,
  },
  "three-chickens": {
    name: "Three Chickens",
    headerBanner: ThreeChickensHeader,
    signupBanner: ThreeChickensSignup,
  },
};

export default gameConfig;
