import {
  Country,
  ReferrerInfo,
  OAuthProvider,
  OAuthResponse,
  FileInfo,
} from "@nestcoinco/hyper-api-gateway-api-client";

const SELECTED_COUNTRY_KEY = "hyper_selected_country";
const SELECTED_EARNING_KEY = "hyper_earning_goal";
const REFERRER_INFO_KEY = "hyper_referrer_info";
const USER_KEY = "hyper_user";
const AUTH_PROVIDER_KEY = "hyper_auth_provider";
const AUTH_CANCELLED_ERROR_KEY = "hyper_auth_cancelled";
const APK_KEY = "hyper_apk";
const PAYMENT_TRIALS = "payment_trials";

export function setStoredSelectedCountry(country: Country) {
  sessionStorage.setItem(SELECTED_COUNTRY_KEY, JSON.stringify(country));
}

export function getStoredSelectedCountry(): Country | null {
  const storedCountry = sessionStorage.getItem(SELECTED_COUNTRY_KEY);
  return storedCountry ? JSON.parse(storedCountry) : null;
}

export function setStoredSelectedEarnings(hyperAmount: number) {
  sessionStorage.setItem(SELECTED_EARNING_KEY, JSON.stringify(hyperAmount));
}

export function getStoredSelectedEarning(): number | null {
  const storedEarning = sessionStorage.getItem(SELECTED_EARNING_KEY);
  return storedEarning ? JSON.parse(storedEarning) : null;
}

export function setStoredReferrerInfo(referrerInfo: ReferrerInfo) {
  localStorage.setItem(REFERRER_INFO_KEY, JSON.stringify(referrerInfo));
}

export function getStoredReferrerInfo(): ReferrerInfo | null {
  const storedReferrerInfo = localStorage.getItem(REFERRER_INFO_KEY);
  return storedReferrerInfo ? JSON.parse(storedReferrerInfo) : null;
}

export function clearStoredReferrerInfo() {
  localStorage.removeItem(REFERRER_INFO_KEY);
}

export function setStoredUser(user: OAuthResponse) {
  sessionStorage.setItem(USER_KEY, JSON.stringify(user));
}

export function getStoredUser(): OAuthResponse | null {
  const storedUser = sessionStorage.getItem(USER_KEY);
  return storedUser ? JSON.parse(storedUser) : null;
}

export function setStoredAuthProvider(provider: OAuthProvider) {
  localStorage.setItem(AUTH_PROVIDER_KEY, JSON.stringify(provider));
}

export function getStoredAuthProvider(): OAuthProvider | null {
  const storedAuthProvider = localStorage.getItem(AUTH_PROVIDER_KEY);
  return storedAuthProvider ? JSON.parse(storedAuthProvider) : null;
}

interface AuthCancelledError {
  error: string;
  provider: string;
}
export function setStoredAuthCancelledError(error: AuthCancelledError) {
  sessionStorage.setItem(AUTH_CANCELLED_ERROR_KEY, JSON.stringify(error));
}

export function getStoredAuthCancelledError(): AuthCancelledError | null {
  const error = sessionStorage.getItem(AUTH_CANCELLED_ERROR_KEY);
  return error ? JSON.parse(error) : null;
}

export function setStoredApk(apk: FileInfo) {
  sessionStorage.setItem(APK_KEY, JSON.stringify(apk));
}

export function getStoredApk(): FileInfo | null {
  const storedApk = sessionStorage.getItem(APK_KEY);
  return storedApk ? JSON.parse(storedApk) : null;
}

export function clearStoredAuthCancelledError() {
  sessionStorage.removeItem(AUTH_CANCELLED_ERROR_KEY);
}

export function setPaymentTrials(trials: number) {
  sessionStorage.setItem(PAYMENT_TRIALS, trials.toString())
}

export function getPaymentTrials(): number | null {
  const paymentTrials = sessionStorage.getItem(PAYMENT_TRIALS);
  return paymentTrials ? Number(paymentTrials) : null;
}

export function clearPaymentTrials() {
  return sessionStorage.removeItem(PAYMENT_TRIALS);
}

export function clearSessionStorage() {
  sessionStorage.clear();
}
