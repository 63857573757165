export enum WEBPAGE {
  TERMS_AND_CONDITIONS = "https://mvm.gg/hyper/terms",
  PRIVACY_POLICY = "https://mvm.gg/hyper/privacy",
}

export enum VIDEO {
  HOW_TO_INSTALL_HYPER = "https://www.youtube.com/embed/xSZXx5jIFak?loop=1&fs=0&playsinline=0&controls=0&autoplay=1&playlist=xSZXx5jIFak",
}

export enum SOCIAL {
  WHATSAPP = "https://chat.whatsapp.com/F0vmrfHNfDxGUGYh087VHM",
  X = "https://twitter.com/metaversemagna",
}
