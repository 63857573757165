import React, { useCallback, useEffect, useRef, useState } from "react";
import "./SignUpPage.scss";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import {
  OAuthProvider,
  LatestWinnersList,
  TournamentWinner,
} from "@nestcoinco/hyper-api-gateway-api-client";
import { Link } from "react-router-dom";
import Lottie from "lottie-react";

import HyperTextIcon from "../../../images/group_hyper_word_icon.svg";
import GoogleSmallButtonIcon from "../../../images/google_small_button_icon.svg";
import Gift from "../../../images/gift.svg";
import PrimaryActionButton from "../../../components/Buttons/PrimaryActionButton/PrimaryActionButton";
import { handleInitiateAuth } from "../../../api-clients/usersAuthClient";
import { renderHeaders } from "../../../utils/renderHeaders";
import {
  formatCurrency,
  getCurrentUrl,
  registerTrackingEvent,
  getTimeAgo,
} from "../../../utils/helpers";
import FacebookLogo from "../../../images/facebook-logo.svg";
import { LINKS, PAGE_NAMES, TRACKING_EVENTS } from "../../../utils/constants";
import {
  clearStoredAuthCancelledError,
  getStoredAuthCancelledError,
  setStoredAuthProvider,
  getStoredSelectedEarning,
} from "../../../utils/storage";
import BackButton from "../../../components/Buttons/BackButton/BackButton";
import ImageHyperCoin from "../../../images/hyper-coin.svg";
import { showErrorToast } from "../../../utils/show-toast";
import { handleGetLatestWinners } from "../../../api-clients/tournamentsExternalsClient";
import ShieldAnimation from "../../../assets/animations/shield-with-hand.json";
import ImageShimmer from "../../../images/shimmer.svg";

const isFacebookButtonEnabled =
  process.env.REACT_APP_ENABLE_FACEBOOK_BUTTON === "true";
const ITEM_FLIP_INTERVAL = 3000;
const WINNERS_REFETCH_INTERVAL = 30000;

function SignUpPage() {
  const [indexOfItemInView, setIndexOfItemInView] = useState(0);
  const [latestWinners, setLatestWinners] = useState<LatestWinnersList>();
  const timeoutIdRef = useRef<NodeJS.Timeout>();
  const storedEarning = getStoredSelectedEarning();

  const handleAuthClick = (provider: OAuthProvider) => {
    registerTrackingEvent({
      name:
        provider === OAuthProvider.GOOGLE
          ? TRACKING_EVENTS.CONTINUE_WITH_GOOGLE
          : TRACKING_EVENTS.CONTINUE_WITH_FACEBOOK,
      payload: { page_name: PAGE_NAMES.signup, url: getCurrentUrl() },
    });
    setStoredAuthProvider(provider);
    handleInitiateAuth(provider);
  };

  const getWinners = useCallback(async () => {
    const winners = await handleGetLatestWinners();
    setLatestWinners(winners);
  }, []);

  useEffect(() => {
    if (!getStoredAuthCancelledError()) return;
    showErrorToast("Unable to create account. Try again");

    return () => {
      clearStoredAuthCancelledError();
    };
  }, []);

  useEffect(() => {
    clearTimeout(timeoutIdRef.current);

    const timeoutId = setTimeout(() => {
      if (
        latestWinners?.records &&
        indexOfItemInView === latestWinners?.records?.length - 1
      ) {
        setIndexOfItemInView(0);
      } else {
        latestWinners?.records && setIndexOfItemInView((prev) => prev + 1);
      }
      timeoutIdRef.current = timeoutId;
    }, ITEM_FLIP_INTERVAL);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [indexOfItemInView, latestWinners?.records]);

  useEffect(() => {
    getWinners();

    const intervalId = setInterval(() => {
      getWinners();
    }, WINNERS_REFETCH_INTERVAL);

    return () => {
      clearInterval(intervalId);
    };
  }, [getWinners]);

  return (
    <>
      {renderHeaders({
        title:
          "Sign Up On Hyper Now To Start Earning | Hyper by Metaverse Magna",
        description:
          "Sign up now to play casual games ike candy crush, temple run, wordler with friends & frenemies for prize money",
      })}
      <Container className="MobileOnly position-relative SignUpPage">
        <BackButton />
        <Col>
          <Link to="/">
            <Image className="signUpToHyperTextImage" src={HyperTextIcon} />
          </Link>
        </Col>
        <div className="sliderContainer">
          {latestWinners?.records?.length &&
            latestWinners.records.map((latestWinner, i) => (
              <LastWinItem
                key={i}
                indexOfItemInView={indexOfItemInView}
                index={i}
                winner={latestWinner}
              />
            ))}
        </div>
        <Col className="imageGamepadContainer">
          <Lottie
            animationData={ShieldAnimation}
            loop={false}
            className="gamePadAnimation"
          />
        </Col>
        <h6 className="areYouReadyText">
          Are you ready to make{" "}
          <Image src={ImageHyperCoin} alt="hyper coin" className="coin" />
          {storedEarning ? formatCurrency(storedEarning) : ""}?
        </h6>
        <h1 className="createAccountText">
          Create a free account to get started!
        </h1>
        <Container className="d-flex justify-content-center signUpButtonContainer mt-0">
          <div className="mb-2 position-relative">
            <div className="text-white completeSignUpWelcomeContainer animated-background">
              <Image src={ImageShimmer} alt="shimmer" className="shimmer" />
              <span className="completeSignUpWelcomeText">
                <Image className="me-1" src={Gift} height={12} width={12} />{" "}
                +100% Bonus
              </span>
            </div>
            <PrimaryActionButton
              textColor={"#FFFFFF"}
              text="Continue with Google"
              onClick={() => handleAuthClick(OAuthProvider.GOOGLE)}
              leftImage={GoogleSmallButtonIcon}
              imgClassName="googleSignInIcon mx-2"
              textClassName="authButtonText"
            />
          </div>
          {isFacebookButtonEnabled && (
            <PrimaryActionButton
              textColor={"#000000"}
              text="Continue with Facebook"
              onClick={() => handleAuthClick(OAuthProvider.FACEBOOK)}
              leftImage={FacebookLogo}
              imgClassName="googleSignInIcon mb-1 mx-2"
              style={{ backgroundColor: "#E9E9E9" }}
              textClassName="authButtonText"
            />
          )}
        </Container>
        <Container className="footerContainer MobileOnly">
          <span className="text-white footerText">
            By continuing, you agree that you are 18+ years old and you accept
            our
            <a
              href={LINKS.WEBPAGE.TERMS_AND_CONDITIONS}
              className="px-1"
              target="_blank"
              rel="noreferrer"
            >
              Terms & Conditions
            </a>
            and
            <a
              href={LINKS.WEBPAGE.PRIVACY_POLICY}
              className="px-1"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </span>
        </Container>
      </Container>
    </>
  );
}

function LastWinItem({
  index,
  indexOfItemInView,
  winner,
}: {
  index: number;
  indexOfItemInView: number;
  winner: TournamentWinner;
}) {
  const isCurrentItem = index === indexOfItemInView;

  return (
    <div
      className="winnersDataContainer"
      style={{
        top: isCurrentItem ? 0 : -100,
        bottom: isCurrentItem ? 0 : undefined,
      }}
    >
      <Image src={winner.avatarUrl} alt="user" className="winnerAvatarImg" />
      <p className="winnerInfo">
        @{winner.username} won{" "}
        <Image
          src={ImageHyperCoin}
          alt="hyper coin"
          height="12px"
          width="12px"
        />{" "}
        {winner.tournament?.rewardAmount}{" "}
      </p>
      <span className="separatorDot" />
      <p className="winningTimeText">
        {getTimeAgo(winner.tournament?.endDate)}
      </p>
    </div>
  );
}

export default SignUpPage;
