import React, { type ReactNode } from "react";
import { Button, type ButtonProps } from "react-bootstrap";

import "./PrimaryActionButton.scss";

type IPrimaryActionButtonProps = ButtonProps & {
  textColor?: string;
  text: ReactNode;
  leftImage?: string;
  rightImage?: string;
  textClassName?: string;
  buttonClassName?: string;
  imgClassName?: string;
};

function PrimaryActionButton({
  textColor,
  text,
  onClick,
  disabled,
  leftImage,
  rightImage,
  imgClassName,
  textClassName,
  buttonClassName,
  ...restProps
}: IPrimaryActionButtonProps) {
  const TextStyle = {
    color: textColor,
  };

  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      className={buttonClassName ? `${buttonClassName}` : `PrimaryButton`}
      {...restProps}
    >
      <span
        className={textClassName ? `${textClassName}` : `buttonTextClassName`}
        style={TextStyle}
      >
        {" "}
        {leftImage && (
          <img
            className={` ${imgClassName}`}
            src={leftImage}
            alt="button_image"
          />
        )}{" "}
        {text}{" "}
        {rightImage && (
          <img
            className={` ${imgClassName}`}
            src={rightImage}
            alt="button_image"
          />
        )}{" "}
      </span>
    </Button>
  );
}

export default PrimaryActionButton;
