import React, { useLayoutEffect, useRef, useState } from "react";
import { Container, Col, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FiatCurrency } from "@nestcoinco/hyper-api-gateway-api-client";

import OnboardingHeader from "../../../components/Headers/OnboardingHeader/OnboardingHeader";
import HyperCoin from "../../../images/hyper-coin.svg";
import "./HowMuchWouldYouLikeToMake.scss";
import {
  HYPER_COIN_TO_NAIRA_RATE,
  HYPER_COIN_TO_DOLLAR_RATE,
  ROUTES,
  TRACKING_EVENTS,
} from "../../../utils/constants";
import { formatCurrency } from "../../../utils/helpers";
import { setStoredSelectedEarnings } from "../../../utils/storage";
import { renderHeaders } from "../../../utils/renderHeaders";
import { registerTrackingEvent, getCurrentUrl } from "../../../utils/helpers";

type HomuchToMakeSelectionItemProps = {
  hyperAmount: string;
  amount: string;
  selected: string;
  setSelected: (value: string) => void;
};

function HomuchToMakeSelectionItem({
  hyperAmount,
  amount,
  selected,
  setSelected,
}: HomuchToMakeSelectionItemProps) {
  const isSelected = selected === hyperAmount;

  const buttonStyle: React.CSSProperties = {
    borderColor: isSelected ? "#6C53E8" : "#352B4D",
    boxShadow: isSelected ? "0px 8px 16px 0px #00000069" : undefined,
  };

  const handleClick = () => {
    setStoredSelectedEarnings(+hyperAmount);
    setSelected(hyperAmount);
  };

  return (
    <button
      className="mx-0 my-0 how-much-to-make-item"
      onClick={handleClick}
      style={buttonStyle}
      aria-pressed={selected === hyperAmount}
    >
      <span className="d-block text-white hyper-amount-to-make-span">
        <Image src={HyperCoin} alt="hyper_coin" className="hyper-coin me-1" />
        {formatCurrency(+hyperAmount)}
      </span>

      <span className="d-flex justify-content-center bottom-container-span">
        <span className="text-white amount-text">{amount}</span>
      </span>
    </button>
  );
}

const hyperCoinEarningGoalsNaira = [1000, 5000, 10000, 20000];
const hyperCoinEarningGoalsDollar = [1000, 5000, 10000, 20000];
const REDIRECT_DELAY = 500;

function HowMuchWouldYouLikeToMake() {
  const navigate = useNavigate();
  const [selectedEarning, setSelectedEarning] = useState<string>("");
  const [selectedCurrency, setSelectedCurrency] = useState<FiatCurrency>(
    FiatCurrency.NGN
  );
  const timeoutIdRef = useRef<NodeJS.Timeout>();

  useLayoutEffect(() => {
    if (!selectedEarning) return;

    (async () => {
      registerTrackingEvent({
        name: TRACKING_EVENTS.WEB_CHOOSE_REWARD,
        payload: {
          amount: selectedEarning,
          currency: selectedCurrency,
          url: getCurrentUrl(),
        },
      });

      const timeoutId = setTimeout(() => {
        navigate(`${ROUTES.ROOT.ONBOARDING}/${ROUTES.ONBOARDING.SIGN_UP}`);
      }, REDIRECT_DELAY);

      timeoutIdRef.current = timeoutId;
    })();

    return () => {
      clearTimeout(timeoutIdRef.current);
    };
  }, [navigate, selectedEarning, selectedCurrency]);

  return (
    <>
      {renderHeaders({
        title: "Choose Desired Earnings | Hyper by Metaverse Magna",
        description:
          "Earnings on Hyper are limitless. It depends on how skilled you are and how often you play. Start playing now",
      })}
      <Container className="MobileOnly position-relative how-much-would-you-like-to-make-page px-3">
        <Col className="d-flex">
          <Col className="how-much-would-you-like-to-make-header">
            <OnboardingHeader amountActive={1} />
          </Col>

          <Col className="flex-column how-much-would-you-like-to-make-page-content">
            <h1 className="earnings-target-heading">Set earnings target!</h1>
            <p className="text-white how-much-would-you-like-to-make">
              How much would you like to make on your first day?
            </p>
            <div className="d-flex justify-content-start">
              <select
                name=""
                id=""
                className="text-white currency-dropdown"
                onChange={(e) =>
                  setSelectedCurrency(e.target.value as FiatCurrency)
                }
              >
                {Object.values(FiatCurrency).map((currency) => (
                  <option value={currency} key={currency}>
                    {currency}
                  </option>
                ))}
              </select>
            </div>
            <Col className="mx-0 mt-2 how-to-make-selection-grid-view">
              {selectedCurrency === FiatCurrency.NGN &&
                hyperCoinEarningGoalsNaira.map((goal) => (
                  <HomuchToMakeSelectionItem
                    key={goal}
                    hyperAmount={goal.toString()}
                    amount={formatCurrency(
                      goal * HYPER_COIN_TO_NAIRA_RATE,
                      "naira"
                    )}
                    selected={selectedEarning}
                    setSelected={setSelectedEarning}
                  />
                ))}
              {selectedCurrency === FiatCurrency.USD &&
                hyperCoinEarningGoalsDollar.map((goal) => (
                  <HomuchToMakeSelectionItem
                    key={goal}
                    hyperAmount={goal.toString()}
                    amount={formatCurrency(
                      Math.floor(goal * HYPER_COIN_TO_DOLLAR_RATE),
                      "dollar"
                    )}
                    selected={selectedEarning}
                    setSelected={setSelectedEarning}
                  />
                ))}
            </Col>
          </Col>
        </Col>
      </Container>
    </>
  );
}

export default HowMuchWouldYouLikeToMake;
