import React, { useEffect, useRef, useState } from "react";
import { Container, Col, Image } from "react-bootstrap";

import ImageHyperCoin from "../../../images/hyper-coin.svg";
import ExplainerImage from "../../../images/download_tutorial.gif";
import ImageWhatsappIcon from "../../../images/whatsapp.svg";
import PrimaryActionButton from "../../../components/Buttons/PrimaryActionButton/PrimaryActionButton";
import "./SignUpCongratulationsPage.scss";
import { renderHeaders } from "../../../utils/renderHeaders";
import { registerTrackingEvent, getCurrentUrl } from "../../../utils/helpers";
import { getStoredUser } from "../../../utils/storage";
import { LINKS, TRACKING_EVENTS } from "../../../utils/constants";
import Lightbox from "../../../components/Lightbox/Lightbox";
import { handleUpdateOnboardingState } from "../../../api-clients/userAccountClient";

const videoTitle = "How To Install Hyper Gaming App";

function SignUpCongratulationsPage() {
  const [storedUser] = useState(getStoredUser()?.user);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const hasWatchedtutorialRef = useRef(false);

  useEffect(() => {
    if (!storedUser) return;
    registerTrackingEvent({
      name: TRACKING_EVENTS.COMPLETE_WEB_ONBOARDING,
      payload: {
        user_id: storedUser.id,
        email: storedUser.email,
        username: storedUser.username,
        url: getCurrentUrl(),
      },
    });
  }, [storedUser]);

  useEffect(() => {
    if (!isLightboxOpen || hasWatchedtutorialRef.current) return;

    (async () => {
      await handleUpdateOnboardingState({ watchedTutorial: true });
      hasWatchedtutorialRef.current = true;
    })();
  }, [isLightboxOpen]);

  return (
    <>
      {renderHeaders({
        title: "Sign Up Reward | Hyper by Metaverse Magna",
        description:
          "You have been rewarded for signing up on Hyper. Claim your reward",
      })}
      <Container className="MobileOnly sign-up-congratulations-page px-0">
        <div className="congratulations-content-container">
          <Col className="sign-up-congratulations-content">
            <div className="coins-container">
              <Image
                src={ImageHyperCoin}
                alt="hyper coin"
                className="hyper-coin-lower"
              />
              <Image
                src={ImageHyperCoin}
                alt="hyper coin"
                className="hyper-coin-upper"
              />
            </div>
            <h1 className="congratulation-text text-white">
              <span className="bonus-percentage-text">100%</span> <br />{" "}
              Insurance Bonus
            </h1>
          </Col>
          <p className="reward-instructions-text text-white">
            <span className="username">{storedUser?.username || "Hey"}</span>,
            install the Hyper app from your download folder and claim your
            bonus!
          </p>

          <Image
            src={ExplainerImage}
            alt="instal_explainer_image"
            className="install-explainer-image"
          />
        </div>

        <Container className="congrats-footer-container MobileOnly">
          <p className="need-help-installing-text">Need help installing?</p>
          <Col className="button-row">
            <PrimaryActionButton
              textColor={"#FFFFFF"}
              text="WATCH TUTORIAL"
              textClassName="button-text"
              buttonClassName="watch-tutorial-button"
              onClick={() => setIsLightboxOpen(true)}
            />
            <PrimaryActionButton
              as="a"
              href={LINKS.SOCIAL.WHATSAPP}
              color={"#27B43E"}
              textColor={"#FFFFFF"}
              text="GET HELP"
              leftImage={ImageWhatsappIcon}
              imgClassName="icon-class"
              textClassName="button-text"
              buttonClassName="get-help-button"
            />
          </Col>
        </Container>
        <Lightbox
          src={LINKS.VIDEO.HOW_TO_INSTALL_HYPER}
          title={videoTitle}
          isOpen={isLightboxOpen}
          setIsOpen={setIsLightboxOpen}
        />
      </Container>
    </>
  );
}

export default SignUpCongratulationsPage;
