import React, { useEffect, useState, useRef } from "react";
import { Button, Image } from "react-bootstrap";
import { Avatar, AvatarList } from "@nestcoinco/hyper-api-gateway-api-client";

import {
  handleUpdateUser,
  handleUploadAvatar,
} from "../../../api-clients/userAccountClient";
import { CustomSpinner } from "../../CustomSpinner/CustomSpinner";
import BackIcon from "../../../images/arrow-left-black.svg";
import CameraIcon from "../../../images/camera.svg";
import CheckmarkIcon from "../../../images/checkmark_successful.svg";
import { handleFetchAvatars } from "../../../api-clients/usersAvatarsClient";
import { useUsernamePageContext } from "../../../context/username-page-context";
import "./AvatarModal.scss";

interface AvatarModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const colors = [
  "#E2DAFD",
  "#FFD499",
  "#F7D3BA",
  "#CDB9B0",
  "#D0F0FE",
  "#CBF4D2",
];

const getRandomColor = () => {
  const randomIndex = Math.floor(Math.random() * colors.length);
  return colors[randomIndex];
};

function AvatarModal({ isOpen, setIsOpen }: AvatarModalProps) {
  const profilePicRef = useRef<HTMLInputElement | null>(null);
  const [avatarFiles, setAvatarFiles] = useState<FileList | null>(null);
  const [avatars, setAvatars] = useState<AvatarList>();
  const [selectedAvatar, setSelectedAvatar] = useState<Avatar | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { setProfile } = useUsernamePageContext();

  useEffect(() => {
    if (!avatarFiles) return;

    const uploadAvatar = async () => {
      setIsLoading(true);
      const userProfile = await handleUploadAvatar(avatarFiles[0]);
      setIsLoading(false);
      setAvatarFiles(null);
      if (!userProfile) return;
      setProfile(userProfile);
      setIsOpen(false);
    };

    uploadAvatar();
  }, [avatarFiles, setIsOpen, setProfile]);

  useEffect(() => {
    (async () => {
      const userAvatars = await handleFetchAvatars();
      setAvatars(userAvatars);
    })();
  }, []);

  const handleChooseFile = () => {
    if (profilePicRef.current) {
      profilePicRef.current.click();
    }
  };

  useEffect(() => {
    if (!selectedAvatar) return;

    async function updateAvatar() {
      setIsLoading(true);
      const user = await handleUpdateUser({ avatarId: selectedAvatar?.id });
      setIsLoading(false);
      setSelectedAvatar(null);
      if (!user) return;
      setProfile(user);
      setIsOpen(false);
    }

    updateAvatar();
  }, [selectedAvatar, setIsOpen, setProfile]);

  return (
    <div className="avatar-selector-container MobileOnly" data-open={isOpen}>
      <div className="d-flex pt-5">
        <Button
          className="bg-transparent border-0"
          onClick={() => setIsOpen(false)}
        >
          <Image src={BackIcon} />
        </Button>
      </div>
      <h3 className="avatar-selector-heading ps-3 mt-2">
        Select a preferred avatar from these presets or upload one.
      </h3>
      {isLoading && (
        <div className="d-flex justify-content-center  py-2 ">
          <CustomSpinner />
        </div>
      )}
      <div className="d-flex flex-wrap justify-justify-content-evenly   avatar-images-container">
        <Button
          className="bg-transparent border-0 p-0"
          onClick={handleChooseFile}
        >
          <Image src={CameraIcon} />
        </Button>
        {avatars?.records?.map((avatar) => (
          <span
            className="avatar-btn-container"
            style={{ backgroundColor: getRandomColor() }}
            key={avatar.id}
          >
            <Button
              className="bg-transparent border-0"
              onClick={() => setSelectedAvatar(avatar)}
            >
              <Image src={avatar.url} className="avatar-img" />
              {selectedAvatar?.id === avatar.id && (
                <Image src={CheckmarkIcon} className="checkmark" />
              )}
            </Button>
          </span>
        ))}
        <input
          type="file"
          ref={profilePicRef}
          className="d-none"
          onChange={(e) => setAvatarFiles(e.target.files)}
        />
      </div>
    </div>
  );
}

export default AvatarModal;
