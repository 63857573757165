import React from "react";
import Image from "react-bootstrap/Image";
import { useNavigate } from "react-router-dom";

import ImageBackButton from "../../../images/back_button.svg";
import "./BackButton.scss";

function BackButton({ style }: { style?: React.CSSProperties }) {
  const navigate = useNavigate();

  return (
    <button className="BackButton" onClick={() => navigate(-1)} style={style}>
      <Image src={ImageBackButton} alt="go back" className="BackButtonIcon" />
    </button>
  );
}

export default BackButton;
