import React, {useState, useEffect, useCallback} from 'react';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Image from "react-bootstrap/Image";
import { Helmet } from 'react-helmet-async';
import Container from "react-bootstrap/Container";
import ProgressBar from 'react-bootstrap/ProgressBar';
import {
  FilesFilesClient,
  FileInfo,
  FileType
} from "@nestcoinco/hyper-api-gateway-api-client";
import Logo from "../../images/hyper-full-purple.svg";
import Trust from "../../images/trust.svg";
import DownloadAnyway from "../../images/download-anyway.svg";
import MetaImage from "../../images/game-detail-meta-image.png";
import "./DownloadNow.scss";
import apiConfig from '../../config/api-config';
import defaultAPK from "../../config/default-apk";
import Lightbox from '../../components/Lightbox/Lightbox';
import { LINKS } from '../../utils/constants';

const COUNT_DOWN = 5;
const videoTitle = "How To Install Hyper Gaming App";
function DownloadNow() {
  const [showStartDownload, setShowStartDownload] = useState(true);
  const [apks, setApks] = useState<Array<FileInfo>>([]);
  const [timeLeft, setTimeLeft] = useState(COUNT_DOWN);
  const [showTutorial, setShowTutorial] = useState(false);
    
  const startDownload = useCallback(() => {
    setShowStartDownload(false);
    setTimeLeft(0);
    const apk = apks.length? apks[0]: defaultAPK;
    window.location.href = apk.url;
  }, [apks]);

  const fetchAPKs = async () => {
    const fileClient = new FilesFilesClient(apiConfig);
    const fileList = (await fileClient.getFilesInfo(1, 1, undefined, FileType.APK)).data;
    setApks(fileList.records.length ? fileList.records : [defaultAPK]);
  };

  useEffect(() => {
    fetchAPKs().catch();
  }, []);

  useEffect(() => {
    if (showStartDownload) {
      const interval: any = setInterval(() => {
        if (timeLeft <= 0) {
          startDownload();
          return () => {
            clearInterval(interval);
          };
        }

        setTimeLeft((p) => p - 1);
      }, 1000);

      return () => {
        clearInterval(interval);
      }
    }
  }, [showStartDownload, timeLeft, startDownload]);

  return (
    <>
      <Helmet>
        <title>Play Casual Games, Compete & Win 💰💰💰 | Hyper by Metaverse Magna</title>
        <meta name="description" content="Step 1 to make 💰 from gaming: Sign up on Hyper & claim your welcome bonus"/>
        <meta name="og:title" content="Play Casual Games, Compete & Win 💰💰💰 | Hyper by Metaverse Magna"/>
        <meta name="og:description" content="Step 1 to make 💰 from gaming: Sign up on Hyper & claim your welcome bonus"/>
        <meta name="og:image" content={MetaImage}/>
      </Helmet>
      <Container fluid className="DownloadNow MobileOnly position-relative">
        <Col className="App-bar d-flex justify-content-center px-0 py-4 position-fixed MobileOnly">
          <Link to="/">
            <Image className="img" src={Logo} />
          </Link>
        </Col>
        <Row as="header" className="Header text-center d-flex flex-column position-relative px-4 py-5 mt-4">
          <Col className="my-4 p-4 trusted d-flex flex-column align-items-center">
            <p className="fw-bold fs-6">Trusted by over 10 thousand users</p>
            <Image src={Trust}></Image>
          </Col>
            <Col className="d-flex flex-column">
              <p className="small py-2"> <b>Hyper app is 100% 🔐 Safe.</b> <br/> Tap <b>"Download anyway"</b> if you see this pop up 👇</p>
              <Image src={DownloadAnyway}></Image>
            </Col>
        </Row>
        {showStartDownload &&
            <Col className="App-footer d-flex flex-column align-items-stretch px-0 py-3 position-fixed MobileOnly">
              <Col className="text-white mb-3 download-info">
                <p className="mb-1 text-center">Download is starting in <b>{timeLeft} sec...</b></p>
                <ProgressBar className="P-bar" now={(COUNT_DOWN - timeLeft) * 100 / COUNT_DOWN} />
              </Col>
              <Button onClick={startDownload} className="download big font-reg">
                <span className="d-inline-block mx-1 fw-bolder text-uppercase">start download</span>
              </Button>
            </Col> 
        }
        {!showStartDownload &&
          <Col className="App-footer d-flex flex-column align-items-stretch px-0 py-3 position-fixed MobileOnly">
            <Col className="text-white mb-3 download-info">
              <p className="mb-0 text-center">Hyper is now downloading</p>
            </Col>
            <Col className="d-flex justify-content-around">
              <Button className="download small light-blue text-center" onClick={() => setShowTutorial(true)}>
                <span className="d-inline-block mx-1 fw-bolder text-uppercase">watch tutorial</span>
              </Button>
              <Button className="download small text-center" onClick={startDownload}>
                <span className="d-inline-block mx-1 fw-bolder text-uppercase">re-download</span>
              </Button>
            </Col>
          </Col>
        }
        <Lightbox
          src={LINKS.VIDEO.HOW_TO_INSTALL_HYPER}
          title={videoTitle}
          isOpen={showTutorial}
          setIsOpen={setShowTutorial}
        />
      </Container>
    </>
  );
}

export default DownloadNow;