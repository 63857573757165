import React from "react";
import { createBrowserRouter, Outlet } from "react-router-dom";
import DownloadApp from "./pages/DownloadApp/DownloadApp";
import DownloadNow from "./pages/DownloadNow/DownloadNow";
import GameDetail from "./pages/GameDetail/GameDetail";
import NotFound from "./pages/NotFound/NotFound";
import gameConfig, { IGameConfig } from "./contents/game-config";
import PaymentConfirmation from "./pages/PaymentConfirmation/PaymentConfirmation";
import TopupSuccessful from "./pages/TopupSuccessful/TopupSuccessful";
import HowMuchWouldYouLikeToMake from "./pages/onboarding/HowMuchWouldYouLikeToMake/HowMuchWouldYouLikeToMake";
import SignUpPage from "./pages/onboarding/SignUpPage/SignUpPage";
import LoggingInPage from "./pages/onboarding/LoggingInPage/LoggingInPage";
import SignUpSuccessfulPage from "./pages/onboarding/SignUpSuccessfulPage/SignUpSuccessfulPage";
import ReferralCodePage from "./pages/onboarding/ReferralCodeScreen/ReferralCodePage";
import CreateUserNamePage from "./pages/onboarding/CreateUserNamePage/CreateUserNamePage";
import SignUpCongratulationsPage from "./pages/onboarding/SignUpCongratulationsPage/SignUpCongratulationsPage";
import DownloadInstructionsPage1 from "./pages/onboarding/DownloadInstructionsPage1/DownloadInstructionsPage1";
import { ProtectedRoute } from "./components/ProtectedRoute/ProtectedRoute";
import LoginSuccessfulPage from "./pages/onboarding/LoginSuccessfulPage/LoginSuccessfulPage";
import WelcomeBackPage from "./pages/onboarding/WelcomeBackPage/WelcomeBackPage";
import PaymentInitiation from "./pages/PaymentInitiation/PaymentInitiation";
import Homepage from "./pages/Homepage/Homepage";
import { UsernamePageContextProvider } from "./context/username-page-context";
import { LEGACY_FLOW_SUFFIX, ROUTES } from "./utils/constants";
import TopupFailed from "./pages/TopupFailed/TopupFailed";
import TopupPending from "./pages/TopupPending/TopupPending";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Homepage />,
  },
  {
    path: "/download",
    element: <DownloadApp />,
  },
  ...generateGameDetailRoutes(gameConfig),
  {
    path: "/download-now",
    element: <DownloadNow />,
  },
  {
    path: ROUTES.TOPUP.FIRST_TOPUP_SUCCESSFUL,
    element: <TopupSuccessful />,
  },
  {
    path: ROUTES.TOPUP.RETURN_TOPUP_SUCCESSFUL,
    element: <TopupSuccessful />,
  },
  {
    path: ROUTES.TOPUP.FAILED,
    element: <TopupFailed />,
  },
  {
    path: ROUTES.TOPUP.PENDING,
    element: <TopupPending />,
  },
  {
    path: `${ROUTES.ROOT.ONBOARDING}`,
    element: <Outlet />,
    children: [
      {
        path: ROUTES.ONBOARDING.SIGN_UP,
        element: <SignUpPage />,
      },
      {
        path: ROUTES.ONBOARDING.SUCCESSFUL,
        element: (
          <ProtectedRoute>
            <SignUpSuccessfulPage />
          </ProtectedRoute>
        ),
      },
      {
        path: ROUTES.ONBOARDING.SELECT_REWARD,
        element: <HowMuchWouldYouLikeToMake />,
      },
      {
        path: ROUTES.ONBOARDING.REFERRAL_CODE,
        element: (
          <ProtectedRoute>
            <ReferralCodePage />
          </ProtectedRoute>
        ),
      },
      {
        path: ROUTES.ONBOARDING.USERNAME,
        element: (
          <ProtectedRoute>
            <UsernamePageContextProvider>
              <CreateUserNamePage />
            </UsernamePageContextProvider>
          </ProtectedRoute>
        ),
      },
      {
        path: ROUTES.ONBOARDING.CONGRATULATIONS,
        element: (
          <ProtectedRoute>
            <SignUpCongratulationsPage />
          </ProtectedRoute>
        ),
      },
      {
        path: ROUTES.ONBOARDING.START_DOWNLOAD,
        element: <DownloadInstructionsPage1 />,
      },
      {
        path: ROUTES.ONBOARDING.LOGGING_IN,
        element: <LoggingInPage />,
      },
      {
        path: ROUTES.ONBOARDING.LOG_IN,
        element: (
          <ProtectedRoute>
            <LoginSuccessfulPage />
          </ProtectedRoute>
        ),
      },
      {
        path: ROUTES.ONBOARDING.WELCOME_BACK,
        element: (
          <ProtectedRoute>
            <WelcomeBackPage />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: ROUTES.TOPUP.PAYMENT_CONFIRMATION,
    element: <PaymentConfirmation />,
  },
  {
    path: ROUTES.TOPUP.PAYMENT_INITIATION,
    element: <PaymentInitiation />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

export default router;

function generateGameDetailRoutes(gameConfig: Record<string, IGameConfig>) {
  const routes = Object.keys(gameConfig).flatMap((slug) => {
    const paths = [`/${slug}`, `/${slug}-${LEGACY_FLOW_SUFFIX}`];
    return paths.map((path) => ({
      path,
      element: <GameDetail slug={slug} />,
    }));
  });
  return routes;
}
